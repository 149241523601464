.video_wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video_wrapper #my-video {
  width: 100%;
  height: 100%;
  background-color: black;
  -o-object-fit: contain;
  object-fit: contain;
}

.vjs-big-play-button {
  display: none !important;
}

.active_playlist {
  display: block !important;
}

.vjs-control-bar {
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 100px !important;
  display: grid;
  grid-gap: 5px;
}

x.vjs-control-bar {
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 100px !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.vjs-control-bar .vjs-play-progress {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(red),
    to(pink)
  );
  background: linear-gradient(to right, red, pink);
}

.vjs-control-bar .vjs-progress-holder {
  height: 10px !important;
  border-radius: 5px;
}

.vjs-control-bar .vjs-play-progress:before {
  font-size: 20px !important;
  top: -5px !important;
}

.vjs-control-bar .vjs-slider-bar,
.vjs-control-bar .vjs-load-progress,
.vjs-control-bar .vjs-load-progress div {
  border-radius: 5px !important;
}

x.vjs-control-bar button:hover {
  background: blue;
}

x.vjs-control-bar button,
x.vjs-control-bar div {
  border: 1px solid blue;
}

.vjs-control-bar .previous,
.vjs-control-bar .next {
  /* width: 20px !important;
  margin-left: 20px; */
  width: 30px !important;
  padding: 0 10px !important;
  cursor: pointer;
}

.vjs-control-bar .vjs-play-control {
  /* width: auto !important; */
  width: 70px !important;
}

.vjs-control-bar .vjs-icon-placeholder:before {
  content: "" !important;
  background-size: 60px 60px !important;
  background-repeat: no-repeat !important;
  background-position: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*-- mobile
        background-size: 30px 30px;
        */
  width: 60px !important;
  /* margin-left: 8px; */
}

.vjs-control-bar .vjs-picture-in-picture-control {
  display: none !important;
}

.vjs-control-bar .video_title_box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
}

.vjs-control-bar .video_title_box .vjs-time-divider {
  min-width: 0 !important;
}

.vjs-control-bar .video_title_box .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.vjs-control-bar .video_title_box .time .vjs-current-time,
.vjs-control-bar .video_title_box .time .vjs-duration {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 0 2px !important;
}

.vjs-control-bar .video_title_box .time .vjs-time-divider {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 2px !important;
}

.vjs-control-bar .video_title_box .music_video_title {
  margin-left: 20px;
}

.video-js.vjs-fluid,
xxx.video-js.vjs-16-9,
xxx.video-js.vjs-4-3 {
  margin: 0 !important;
  height: 100vh !important;
}

@media only screen and (min-width: 992px) {
  .vjs-control-bar .library_box {
    position: relative;
    margin-right: 10px;
  }
  .vjs-control-bar .library_box #library {
    width: 30px;
  }
  .vjs-control-bar .library_box .playlist_wrapper {
    display: none;
    position: absolute !important;
    z-index: 11;
    top: -290px !important;
    left: -280px !important;
    width: auto !important;
    height: 300px !important;
    -webkit-transform: none !important;
    transform: none !important;
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
  }
  .vjs-control-bar .library_box .playlist_wrapper .playlist_box {
    width: 300px !important;
    height: 100% !important;
    background: rgba(37, 37, 37, 0.9) !important;
    -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .vjs-control-bar
    .library_box
    .playlist_wrapper
    .playlist_box
    .playlist_title_box {
    font-size: 16px;
  }
  .vjs-control-bar .library_box .playlist_wrapper .playlist_box hr {
    width: 100%;
    height: 1px;
    border: none;
    background: grey;
  }
  .vjs-control-bar
    .library_box
    .playlist_wrapper
    .playlist_box
    .playlist_active {
    background: rgba(0, 0, 0, 0.3);
  }
  .vjs-control-bar
    .library_box
    .playlist_wrapper
    .playlist_box
    .playlist:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  .vjs-control-bar .library_box .playlist_wrapper .playlist_box .playlist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
  }
  .vjs-control-bar .library_box .playlist_wrapper .playlist_box .playlist .num {
    font-size: 12px;
    margin-right: 10px;
  }
  .vjs-control-bar
    .library_box
    .playlist_wrapper
    .playlist_box
    .playlist
    .album_art {
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
  .vjs-control-bar
    .library_box
    .playlist_wrapper
    .playlist_box
    .playlist
    .song_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .vjs-control-bar
    .library_box
    .playlist_wrapper
    .playlist_box
    .playlist
    .song_info
    .song_title {
    font-size: 14px;
  }
  .vjs-control-bar
    .library_box
    .playlist_wrapper
    .playlist_box
    .playlist
    .song_info
    .song_artist {
    font-size: 12px;
    color: darkgrey;
  }
}

.vjs-control-bar .vjs-volume-bar {
  height: 5px !important;
}

.vjs-control-bar .vjs-volume-level {
  height: 4px !important;
}

.vjs-control-bar .vjs-volume-control {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.vjs-control-bar .vjs-mute-control span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.vjs-control-bar .vjs-remaining-time {
  display: none !important;
}

.vjs-control-bar .vjs-fullscreen-control {
  width: auto !important;
  min-width: 60px !important;
}

.vjs-control-bar button {
  height: 60px !important;
}

.vjs-control-bar .vjs-progress-control {
  position: absolute !important;
  top: -5px !important;
  left: 0 !important;
  width: 100% !important;
  height: auto !important;
}

.video-js .vjs-play-control.vjs-paused .vjs-icon-placeholder:before {
  background-image: url(../images/playcolor1.png);
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  background-image: url(../images/pausecolor1.png);
}

.video__close {
  display: none;
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  outline: none;
  background: none;
  font-size: 34px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

@media only screen and (max-width: 550px) {
  .music_video_title {
    position: fixed;
    top: 25px;
    left: 0;
  }
  .vjs-current-time,
  .vjs-duration,
  .vjs-time-divider {
    display: none !important;
  }
  .vjs-volume-control {
    margin-right: 0 !important;
  }
  .vjs-fullscreen-control img {
    width: 30px !important;
  }
}

@media only screen and (min-width: 551px) {
  .vjs-current-time,
  .vjs-duration,
  .vjs-time-divider {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .vjs-fullscreen-control img {
    width: 40px !important;
  }
}

.video-js
  .vjs-fullscreen-control[title="Fullscreen"]
  .vjs-icon-placeholder:before {
  position: relative;
  z-index: 2;
  background-image: url(../images/full-screen.png);
  background-size: 40px 40px !important;
  width: 50px !important;
  height: 50px !important;
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  position: relative;
  z-index: 2;
  background-image: url(../images/fullscreen-exit.png);
  background-size: 40px 40px !important;
  width: 50px !important;
  height: 50px !important;
}

/*# sourceMappingURL=movie.css.map */

/******** added by Adonis 2021-04-15 ****/

.video-js .previous .vjs-icon-placeholder:before,
.video-js .next .vjs-icon-placeholder:before {
  position: absolute;
  left: -20px;
  background-image: url(../images/previouscolor.png);
  background-size: 20px 20px !important;
  padding: 0 !important;
  margin: 0;
}

.video-js .next .vjs-icon-placeholder:before {
  background-image: url(../images/forwardcolor.png);
}

/* .vjs-poster {
  border-radius: 20px;
  width: 100px;
} */

.video-js .video-title {
  text-align: center;
  min-width: 200px !important;
  width: 100% !important;
  /* width: 40vw !important; */
  color: white;
  text-align: center !important;
  height: auto !important;
}

.video-js .video-title .vjs-control-text {
  content: "";
  position: initial !important;
  font-size: 16px;
  padding: 20px;
}

.vjs-has-started .vjs-control-bar {
  display: grid !important;
  align-items: center !important;
  justify-items: center !important;
  grid-template-columns: 80px 30px 30px auto 20px 60px 50px 0;
}

.vjs-has-started .vjs-control-bar.ios,
.vjs-has-started .vjs-control-bar.android {
  grid-template-columns: 80px 30px 30px auto 20px 60px 0 0;
}

.vjs-has-started .vjs-control-bar.ios .vjs-fullscreen-control,
.vjs-has-started .vjs-control-bar.android .vjs-fullscreen-control {
  display: none;
}

.video-js .vjs-current-time,
.video-js .vjs-time-divider,
.video-js .vjs-duration {
  display: inline-block !important;
  padding: 18px 0 !important;
  font-size: 12px !important;
  min-width: 20px !important;
  text-align: center;
}

.video-js .vjs-remaining-time {
  display: none;
}

.video-js .vjs-current-time .vjs-control-text,
.video-js .vjs-duration .vjs-control-text {
  display: none !important;
}

x.vjs-audio-button {
  background: gray !important;
}

.vjs-control.vjs-button.close_modal::before {
  content: "✕";
  position: initial !important;
  font-size: 25px;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 50%;
}

.vjs-control.vjs-button.close_modal {
  position: fixed !important;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  color: white;
  cursor: pointer;
}

.modal-player .modal-wrapper {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.video-js .vjs-control-bar {
  position: fixed;
  bottom: -10px;
}

x.video-js .vjs-tech {
  top: 50%;
  transform: translateY(-50%);
  /* position: absolute;
  left: 0;
  width: 100%;
  height: 100%; */
}

.modal-fullscreen .modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

x.modal-player ion-header {
  top: 50%;
  transform: translateY(-50%);
}

x.vjs-waiting {
  visibility: hidden;
  background: transparent;
}

#preview_video_hero .vjs-loading-spinner,
#preview_video_hero .vjs-control-bar {
  display: none !important;
}

.vjs-button[disabled] {
  opacity: 0.5;
}

ion-toggle {
  --background: #666;
  --background-checked: #b22222;
  /* --handle-background: #7a49a5;*/
  --handle-background-checked: white;
}

.vjs.user-inactive {
  visibility: visible;
  opacity: 0;
  transition: visibility 1s, opacity 1s;
}

.video-js .vjs-control.library-box .vjs-icon-placeholder:before {
  background-image: url(../images/library.png);
  background-size: 30px 30px !important;
  cursor: pointer;
  text-align: center;
  /******* temporarily disabled - 2021-12-02  */
  visibility: hidden;
}

x.library.user-inactive {
  /* display: none; */
  /* opacity: 0;
  transition: opacity 1s; */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.library {
  /* display: block; */
  position: fixed;
  bottom: 100px;
  right: 10px;
  z-index: 100;
  background: rgba(37, 37, 37, 0.9);
  color: white;
  border-radius: 5px;
  padding: 10px;
  max-height: 400px;
  overflow-y: scroll;
}

/* .library .items {
 display: flex; 
}*/

.library .item {
  display: flex;
  align-items: center !important;
  /* display: block; */
  padding: 10px;
  width: 200px;
  cursor: pointer;
  font-size: 12px;
}

.playlist .item:hover {
  background: #555;
}

/* 
.media_toggle_example::before{

  background: blue;
  display: block;
  width: 100px;
  height: 50px;
  position: fixed !important;
  top:200px;
  left:100px;
  z-index: 99999;
}

.vjs-control.vjs-button.media_toggle_example::before {
  content: '✕';
  position: initial !important;
  font-size: 25px;
  padding: 8px 12px;
  background: rgba(0, 0, 0, .9);
  border-radius: 50%;
} */

x.media_toggle,
.media_toggle_2 {
  /* background: blue; */
  width: auto;
  display: flex;
  align-items: center;
  font-size: 11px;
  letter-spacing: 1.2px;
  top: 40px;
  left: 20px;
  position: absolute !important;
  z-index: 999999 !important;
}

x.media_toggle,
.media_toggle_2 {
  /* background: blue; */
  width: 100px;
  height: 30px;
}

.media_toggle_2 {
  top: 60px;
}

.vjs-control.vjs-button.media_toggle {
  position: fixed !important;
  top: 30px;
  left: 20px;
  width: 80px;
  height: 24px !important;
  color: white;
  cursor: pointer;
  background: #b00000;
  border: #b00000 1px solid;
  border-radius: 50px;
}

.vjs-control.vjs-button.media_toggle .vjs-control-text {
  position: relative !important;
  color: white;
  font-size: 11px;
  letter-spacing: 1px;
  padding: 0;
  padding-left: 14px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  /* transition: visibility .4s linear .4s, opacity 300ms; */
}

.vjs-control.vjs-button.media_toggle.is-audio .vjs-control-text {
  letter-spacing: 1px;
  padding-right: 24px;
}

.vjs-control.vjs-button.media_toggle .vjs-icon-placeholder {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: black 1px solid;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: white;
  transform: translate(0%, 0);
}

.vjs-control.vjs-button.media_toggle.is-audio .vjs-icon-placeholder {
  left: 100%;
  transform: translate(-100%, 0);
}

/**** with icons ***/

.vjs-control.vjs-button.media_toggle.with-icon {
  position: fixed !important;
  top: 30px;
  left: 20px;
  width: 60px;
  height: 24px !important;
  color: white;
  cursor: pointer;
  /* background: rgba(0, 0, 0, .9); */
  background: #b22222;
  border: white 1px solid;
  border-radius: 50px;
}

.vjs-control.vjs-button.media_toggle.with-icon .vjs-control-text {
  display: none;
}

.vjs-control.vjs-button.media_toggle.with-icon .vjs-icon-placeholder {
  position: absolute;
  top: -8px;
  left: -10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: white 1px solid;
  background-color: black;
  /* background-image: url(../images/video-player.svg); */
  background-size: 20px 20px !important;
  background-repeat: no-repeat;
  background-position: 7px 7px;
}

.vjs-control.vjs-button.media_toggle.with-icon.is-audio .vjs-icon-placeholder {
  left: 105%;
  /* -webkit-transition: .4s;
  transition: .4s; */
  transform: translate(-100%, 0);
  background-position: 5px 5px;
  /* background-image: url(../images/musical-note.svg); */
}

.vjs-control.vjs-button.media_toggle.with-icon .vjs-icon-placeholder::before {
  position: absolute;
  top: 0px;
  left: 1px;
  border-radius: 50%;
  width: 36px !important;
  height: 36px;
  /* padding:5px; */
  /* border: white 1px solid; */
  /* -webkit-transition: .4s;
  transition: .4s; */
  background-image: url(../images/video-player.svg);
  background-size: 18px 18px !important;
  background-repeat: no-repeat;
  background-position: 7px 7px;
  /* filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%); */
  filter: brightness(0) invert(1);
}

.vjs-control.vjs-button.media_toggle.is-audio.with-icon
  .vjs-icon-placeholder::before {
  /* left: 100%;  */
  top: 2px;
  left: 38px;
  transform: translate(-100%, 0);
  background-position: 5px 5px;
  background-image: url(../images/musical-note.svg);
}

.modal-library {
  z-index: 9999999 !important;
}

.modal-library .modal-wrapper {
  background: rgba(37, 37, 37, 0.9) !important;
  -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.modal-library .modal-wrapper .title-bar {
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
}

.modal-library .modal-wrapper .title {
  font-size: 18px;
}

.modal-library .modal-wrapper .close_modal {
  color: white;
  background: black;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 8px 10px;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border: 1px solid #fff;
}

.modal-library .modal-wrapper .close_modal:hover {
  opacity: 0.5;
}

.modal-library .modal-wrapper .items {
  display: block;
  position: relative;
  overflow: auto;
  height: calc(100vh - 60px);
}

.modal-library .modal-wrapper .items .item {
  cursor: pointer;
  padding: 10px 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50px 1fr;
  letter-spacing: 1px;
}

.modal-library .modal-wrapper .items .item:hover,
.modal-library .modal-wrapper .items .item.playlist_active {
  background: rgba(0, 0, 0, 0.3);
}

.modal-library .modal-wrapper .items .item .name {
  font-size: 15px;
}

.modal-library .modal-wrapper .items .item .artist {
  font-size: 12px;
  color: darkgrey;
}

.modal-library .modal-wrapper .items .item .playlist_active {
  background: rgba(0, 0, 0, 0.3);
}

.modal-library .modal-wrapper .items .item .album_art {
  width: 40px;
  height: 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}

@media only screen and (min-width: 760px) {
  .modal-library .modal-wrapper {
    position: absolute !important;
    right: 0;
    width: 300px;
    bottom: 0;
    top: 0;
    height: 100vh;
  }
}
/*
.modal-player ion-header {
   display:none; 
}*/

/* .sc-ion-modal-md-h {
  z-index:initial !important;
} */


/* 
.video-js,
.video-js:before,
.video-js:after {
  content: "";
  background-image: url("https://app.takeone.studio/assets/img/takeone-logo-square.jpg");
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: 55% calc(50% - 0px);
  border: none !important;
  box-shadow: none !important;
}

*/
.video-js {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  /* background-color: black;
  background-size: cover;
  z-index: 99999; */
}

.player-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
}