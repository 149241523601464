:root{
  --pie-color: white;
}
.example {
  text-align: center;
  padding: 4em;
}

.pie {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: gray;
  background-image: linear-gradient(to right, transparent 50%, var(--pie-color) 0);
  position: relative;
  display: inline-block;
  /* margin: 10px; */
  border: white 2px solid;
}

.pie::before {
  content: "";
  display: block;
  margin-left: 50%;
  height: 100%;
  border-radius: 0 100% 100% 0/50%;
  background-color: inherit;
  transform-origin: left;
}

.pie.with-number::after {
  content: attr(data-value);
  position: absolute;
  width: 70%;
  height: 70%;
  margin: auto;
  border-radius: 50%;
  color: black;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font: 900 20px/41px Tahoma;
}

.pie[data-value="0"]:before {
  transform: rotate(0turn);
}

.pie[data-value="1"]:before {
  transform: rotate(0.01turn);
}

.pie[data-value="2"]:before {
  transform: rotate(0.02turn);
}

.pie[data-value="3"]:before {
  transform: rotate(0.03turn);
}

.pie[data-value="4"]:before {
  transform: rotate(0.04turn);
}

.pie[data-value="5"]:before {
  transform: rotate(0.05turn);
}

.pie[data-value="6"]:before {
  transform: rotate(0.06turn);
}

.pie[data-value="7"]:before {
  transform: rotate(0.07turn);
}

.pie[data-value="8"]:before {
  transform: rotate(0.08turn);
}

.pie[data-value="9"]:before {
  transform: rotate(0.09turn);
}

.pie[data-value="10"]:before {
  transform: rotate(0.1turn);
}

.pie[data-value="11"]:before {
  transform: rotate(0.11turn);
}

.pie[data-value="12"]:before {
  transform: rotate(0.12turn);
}

.pie[data-value="13"]:before {
  transform: rotate(0.13turn);
}

.pie[data-value="14"]:before {
  transform: rotate(0.14turn);
}

.pie[data-value="15"]:before {
  transform: rotate(0.15turn);
}

.pie[data-value="16"]:before {
  transform: rotate(0.16turn);
}

.pie[data-value="17"]:before {
  transform: rotate(0.17turn);
}

.pie[data-value="18"]:before {
  transform: rotate(0.18turn);
}

.pie[data-value="19"]:before {
  transform: rotate(0.19turn);
}

.pie[data-value="20"]:before {
  transform: rotate(0.2turn);
}

.pie[data-value="21"]:before {
  transform: rotate(0.21turn);
}

.pie[data-value="22"]:before {
  transform: rotate(0.22turn);
}

.pie[data-value="23"]:before {
  transform: rotate(0.23turn);
}

.pie[data-value="24"]:before {
  transform: rotate(0.24turn);
}

.pie[data-value="25"]:before {
  transform: rotate(0.25turn);
}

.pie[data-value="26"]:before {
  transform: rotate(0.26turn);
}

.pie[data-value="27"]:before {
  transform: rotate(0.27turn);
}

.pie[data-value="28"]:before {
  transform: rotate(0.28turn);
}

.pie[data-value="29"]:before {
  transform: rotate(0.29turn);
}

.pie[data-value="30"]:before {
  transform: rotate(0.3turn);
}

.pie[data-value="31"]:before {
  transform: rotate(0.31turn);
}

.pie[data-value="32"]:before {
  transform: rotate(0.32turn);
}

.pie[data-value="33"]:before {
  transform: rotate(0.33turn);
}

.pie[data-value="34"]:before {
  transform: rotate(0.34turn);
}

.pie[data-value="35"]:before {
  transform: rotate(0.35turn);
}

.pie[data-value="36"]:before {
  transform: rotate(0.36turn);
}

.pie[data-value="37"]:before {
  transform: rotate(0.37turn);
}

.pie[data-value="38"]:before {
  transform: rotate(0.38turn);
}

.pie[data-value="39"]:before {
  transform: rotate(0.39turn);
}

.pie[data-value="40"]:before {
  transform: rotate(0.4turn);
}

.pie[data-value="41"]:before {
  transform: rotate(0.41turn);
}

.pie[data-value="42"]:before {
  transform: rotate(0.42turn);
}

.pie[data-value="43"]:before {
  transform: rotate(0.43turn);
}

.pie[data-value="44"]:before {
  transform: rotate(0.44turn);
}

.pie[data-value="45"]:before {
  transform: rotate(0.45turn);
}

.pie[data-value="46"]:before {
  transform: rotate(0.46turn);
}

.pie[data-value="47"]:before {
  transform: rotate(0.47turn);
}

.pie[data-value="48"]:before {
  transform: rotate(0.48turn);
}

.pie[data-value="49"]:before {
  transform: rotate(0.49turn);
}

.pie[data-value="50"]:before {
  transform: rotate(0.5turn);
}

.pie[data-value="51"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.01turn);
}

.pie[data-value="52"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.02turn);
}

.pie[data-value="53"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.03turn);
}

.pie[data-value="54"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.04turn);
}

.pie[data-value="55"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.05turn);
}

.pie[data-value="56"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.06turn);
}

.pie[data-value="57"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.07turn);
}

.pie[data-value="58"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.08turn);
}

.pie[data-value="59"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.09turn);
}

.pie[data-value="60"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.1turn);
}

.pie[data-value="61"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.11turn);
}

.pie[data-value="62"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.12turn);
}

.pie[data-value="63"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.13turn);
}

.pie[data-value="64"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.14turn);
}

.pie[data-value="65"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.15turn);
}

.pie[data-value="66"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.16turn);
}

.pie[data-value="67"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.17turn);
}

.pie[data-value="68"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.18turn);
}

.pie[data-value="69"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.19turn);
}

.pie[data-value="70"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.2turn);
}

.pie[data-value="71"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.21turn);
}

.pie[data-value="72"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.22turn);
}

.pie[data-value="73"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.23turn);
}

.pie[data-value="74"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.24turn);
}

.pie[data-value="75"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.25turn);
}

.pie[data-value="76"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.26turn);
}

.pie[data-value="77"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.27turn);
}

.pie[data-value="78"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.28turn);
}

.pie[data-value="79"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.29turn);
}

.pie[data-value="80"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.3turn);
}

.pie[data-value="81"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.31turn);
}

.pie[data-value="82"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.32turn);
}

.pie[data-value="83"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.33turn);
}

.pie[data-value="84"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.34turn);
}

.pie[data-value="85"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.35turn);
}

.pie[data-value="86"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.36turn);
}

.pie[data-value="87"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.37turn);
}

.pie[data-value="88"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.38turn);
}

.pie[data-value="89"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.39turn);
}

.pie[data-value="90"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.4turn);
}

.pie[data-value="91"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.41turn);
}

.pie[data-value="92"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.42turn);
}

.pie[data-value="93"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.43turn);
}

.pie[data-value="94"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.44turn);
}

.pie[data-value="95"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.45turn);
}

.pie[data-value="96"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.46turn);
}

.pie[data-value="97"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.47turn);
}

.pie[data-value="98"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.48turn);
}

.pie[data-value="99"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.49turn);
}

.pie[data-value="100"]:before {
  background-color: var(--pie-color);
  transform: rotate(0.5turn);
}

 