.logo {
  width: 180px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}

.logo img {
  width: 100%;
}

.logo .logo_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -25%);
  transform: translate(-50%, -25%);
  width: calc(100% - 70px);
}

.logo .logo_wrapper .equalizer {
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 5px;
}

.logo .logo_wrapper .equalizer .lines {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0 2px;
  background-image: url("https://takeone.studio/img/gradient-sm.png");
  background-size: 1500%;
  background-repeat: no-repeat;
  background-clip: content-box;
  background-position: bottom;
}

.logo .logo_wrapper .equalizer .one {
  height: 7%;
  -webkit-animation: one 1s infinite linear;
  animation: one 1s infinite linear;
  background-position: 1% 60%;
}

.logo .logo_wrapper .equalizer .two {
  height: 15%;
  -webkit-animation: two 2s infinite linear;
  animation: two 2s infinite linear;
  background-position: 10% 60%;
}

.logo .logo_wrapper .equalizer .three {
  height: 35%;
  -webkit-animation: three 0.2s infinite linear;
  animation: three 0.2s infinite linear;
  background-position: 20% 60%;
}

.logo .logo_wrapper .equalizer .four {
  height: 40%;
  -webkit-animation: four 0.2s infinite linear;
  animation: four 0.2s infinite linear;
  background-position: 10% 70%;
}

.logo .logo_wrapper .equalizer .five {
  height: 30%;
  -webkit-animation: five 0.2s infinite linear;
  animation: five 0.2s infinite linear;
  background-position: 10% 90%;
}

.logo .logo_wrapper .equalizer .six {
  height: 100%;
  -webkit-animation: six 1s infinite linear;
  animation: six 1s infinite linear;
  background-position: 30% 90%;
}

.logo .logo_wrapper .equalizer .seven {
  height: 80%;
  -webkit-animation: seven 1s infinite linear;
  animation: seven 1s infinite linear;
  background-position: 30% 90%;
}

.logo .logo_wrapper .equalizer .eight {
  height: 60%;
  -webkit-animation: eight 0.9s infinite linear;
  animation: eight 0.9s infinite linear;
}

.logo .logo_wrapper .equalizer .nine {
  height: 50%;
  -webkit-animation: nine 2s infinite linear;
  animation: nine 2s infinite linear;
}

.logo .logo_wrapper .equalizer .ten {
  height: 40%;
  -webkit-animation: ten 0.2s infinite linear;
  animation: ten 0.2s infinite linear;
}

.logo .logo_wrapper .equalizer .eleven {
  height: 34%;
  -webkit-animation: eleven 1s infinite linear;
  animation: eleven 1s infinite linear;
}

.logo .logo_wrapper .equalizer .twelve {
  height: 7%;
  -webkit-animation: twelve 2s infinite linear;
  animation: twelve 2s infinite linear;
}

@-webkit-keyframes one {
  0% {
    height: 1%;
  }
  10% {
    height: 5%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 7%;
  }
  100% {
    height: 1%;
  }
}

@keyframes one {
  0% {
    height: 1%;
  }
  10% {
    height: 5%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 7%;
  }
  100% {
    height: 1%;
  }
}

@-webkit-keyframes two {
  0% {
    height: 1%;
  }
  10% {
    height: 13%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 15%;
  }
  100% {
    height: 1%;
  }
}

@keyframes two {
  0% {
    height: 1%;
  }
  10% {
    height: 13%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 15%;
  }
  100% {
    height: 1%;
  }
}

@-webkit-keyframes six {
  0% {
    height: 1%;
  }
  10% {
    height: 100%;
  }
  75% {
    height: 80%;
  }
  100% {
    height: 1%;
  }
}

@keyframes six {
  0% {
    height: 1%;
  }
  10% {
    height: 100%;
  }
  75% {
    height: 80%;
  }
  100% {
    height: 1%;
  }
}

@-webkit-keyframes seven {
  0% {
    height: 1%;
  }
  10% {
    height: 80%;
  }
  75% {
    height: 60%;
  }
  100% {
    height: 1%;
  }
}

@keyframes seven {
  0% {
    height: 1%;
  }
  10% {
    height: 80%;
  }
  75% {
    height: 60%;
  }
  100% {
    height: 1%;
  }
}

@-webkit-keyframes eight {
  0% {
    height: 1%;
  }
  10% {
    height: 60%;
  }
  75% {
    height: 40%;
  }
  100% {
    height: 1%;
  }
}

@keyframes eight {
  0% {
    height: 1%;
  }
  10% {
    height: 60%;
  }
  75% {
    height: 40%;
  }
  100% {
    height: 1%;
  }
}

@-webkit-keyframes nine {
  0% {
    height: 48%;
  }
  10% {
    height: 50%;
  }
  75% {
    height: 47%;
  }
  100% {
    height: 50%;
  }
}

@keyframes nine {
  0% {
    height: 48%;
  }
  10% {
    height: 50%;
  }
  75% {
    height: 47%;
  }
  100% {
    height: 50%;
  }
}

@-webkit-keyframes eleven {
  0% {
    height: 1%;
  }
  10% {
    height: 23%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 34%;
  }
  100% {
    height: 1%;
  }
}

@keyframes eleven {
  0% {
    height: 1%;
  }
  10% {
    height: 23%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 34%;
  }
  100% {
    height: 1%;
  }
}

@-webkit-keyframes twelve {
  0% {
    height: 1%;
  }
  10% {
    height: 5%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 7%;
  }
  100% {
    height: 1%;
  }
}

@keyframes twelve {
  0% {
    height: 1%;
  }
  10% {
    height: 5%;
  }
  40% {
    height: 1%;
  }
  75% {
    height: 7%;
  }
  100% {
    height: 1%;
  }
}

.logo .logo_wrapper .disc {
  width: 200px;
  height: auto;
}
