.main-row-0 {
  width: 100%;
  position: relative;
}

.main-row-0-content {
  text-align: center;
  width: 100% !important;
  padding-top: 90%;
  min-height: 20vh;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8827490457674352) 26%,
    rgba(0, 0, 0, 0) 44%
  );
}

.gradient-shadow-bottom {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 100px;
  /* background: blue; */
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 76%);
  z-index: -1;
}

.hero-image {
  object-fit: cover;
  height: 400px;
  position: absolute;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  left: 0;
  right: 0;
  /* top: 30px; */
  top: 0px;
  bottom: 0;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
  -o-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
  -moz-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
  transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
  /* z-index: 9999; */
  z-index: -1;
}

/*
body {
   font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));  
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300))); 
}*/

.hero-info .title {
  /* font-size: calc(40px + (26 -40) * ((100vw - 300px) / (1600 - 300))) !important;  */
  /* font-size: 5vw !important; */
  font-size: 7vw !important;
  line-height: 7.5vw;
}

.hero-info .artist {
  /* font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300))); */
  font-size: 4vw !important;
  color: #ddd;
}

x.hero-menu-item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.hero-menu-item ion-icon {
  font-size: 20px !important;
}
.hero-menu-item ion-label {
  padding: 0 5px !important;
  font-size: 14px !important;
  text-transform: none !important;
}

.media-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}
video,
.video-preview {
  width: 100% !important;
}
.preview_video_sound:hover {
  opacity: 0.5;
}

.preview_video_sound {
  cursor: pointer;
  position: absolute;
  /* bottom: 40px; */
  z-index: 1;

  top: 30vw;

  bottom: 15px;
  right: 15px;
  width: 30px;
  height: 30px;

  border-radius: 100px;
  border: 5px solid rgba(255, 255, 255, 0.6);
  background: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.preview_video_sound .icon_mute_icon {
  width: 80%;
}
/* ion-slide:hover{
  opacity: .5;
} */

.preview_video_hero-dimensions {
  width: initial !important;
}

.main-preview {
  position: relative;
  cursor: pointer;
  height: 53vw;
  background-position: center;
  background-size: cover;
  border: none;
  margin-top: 40px;
}
.modal-wrapper.sc-ion-modal-md {
  height: 100vh;
}

@media only screen and (min-width: 300px) {
  .preview_video_sound {
    top: 38vw;
  }
}
@media only screen and (min-width: 600px) {
  .main-row-0-content {
    text-align: left;
    padding-left: 20px;
    padding-top: 18%;
    padding-bottom: 8%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7342162704467774) 0%,
      rgba(0, 0, 0, 0) 40%
    );
  }
  .hero-image {
    height: auto;
  }
  .hero-menu-item ion-icon {
    font-size: 20px !important;
  }
  .hero-menu-item ion-label {
    font-size: 14px !important;
  }
  .preview_video_sound {
    top: initial;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
  }
  .hero-info .title {
    font-size: 5vw !important;
    line-height: 5vw;
  }

  .hero-info .artist {
    font-size: 2.5vw !important;
  }
}

@media only screen and (min-width: 768px) {
  .main-preview {
    max-height: 320px !important;
  }
}
@media only screen and (min-width: 800px) {
  .hero-menu-item ion-icon {
    font-size: 30px !important;
  }
  .hero-menu-item ion-label {
    font-size: 16px !important;
  }
  .hero-menu-item ion-button {
    height: 40px !important;
  }
  .main-row-0-content {
    padding-bottom: 4%;
  }
}

.user-access {
  color: black;
}

.color-secondary {
  border: 0px;
  border-radius: 4px;
  background-color: rgba(109, 109, 110, 0.7);
  color: white;
}
.button-transparent {
  border: 0px;
  background-color: transparent;
  color: white;
  /* margin:0 2%; */
}

.button-transparent:hover {
  background-color: none !important;
}
.button-transparent ion-icon {
  font-size: 24px !important;
}
.button-transparent label {
  /* opacity: 0.5; */
  padding: 3px 0;
}

ion-button div {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
ion-button .button-secondary {
  background: none;
}

.at-buttons {
  display: grid;
  grid-template-columns: repeat(3, 80px);
}
.at-buttons ion-button {
  height: 60px;
}
.at-buttons ion-button label {
  padding-top: 10px !important;
}
.at-buttons ion-icon.share {
  font-size: 22px !important;
}

.modal-content.social-share {
  position: absolute;
  bottom: 0;
  /* height: 100px; */
  width: 100%;
}
.social-share-close {
  height: 80px;
  margin: 30px 0 !important;
}
.social-share-close ion-icon {
  background: white !important;
  color: black;
  padding: 10px !important;
  border-radius: 50% !important;
  font-size: 24px !important;
}
