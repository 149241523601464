.at-center {
  text-align: center !important;
}

html,
ion-content {
  scroll-behavior: smooth;
}

ion-tab-bar {
  border: none;
}

ion-toolbar {
  position: absolute;
}

.top-header ion-toolbar {
  --background: -webkit-linear-gradient(
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  --ion-background-color: -webkit-linear-gradient(
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.ios .top-header ion-toolbar,
.android .top-header ion-toolbar {
  --background: transparent;
  --ion-background-color: transparent;
}
ion-toolbar.transparent {
  --background: black;
  --ion-background-color: black;
  transition: max-height 0.25s ease-in;
  height: inherit;
  max-height: 100px;
}
.ios ion-toolbar.transparent,
.android ion-toolbar.transparent {
  /* height: 0; */
  max-height: 0;
  transition: max-height 0.15s ease-out;
}

.top-header .top-menu ion-title img {
  /* padding: 20px !important; */
  padding: 10px 10px 10px 20px !important;
}

ion-content {
  top: 0 !important;
}

.hero {
  height: 90vh;
  min-height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}

.info-modal .modal-wrapper {
  /********* height: 640px !important;  original - disabled on 2022-01-17*/
  height: 550px !important;
  background: #141414;
  --ion-background-color: #141414;
}
.ios.info-modal .modal-wrapper {
  min-height: 600px !important;
}
/* .info-modal.web .modal-wrapper {
  height: 550px !important;
} */

.info-modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.size-10 {
  font-size: 10px;
}
.size-12 {
  font-size: 12px;
}
.size-14 {
  font-size: 14px;
}

.size-16 {
  font-size: 16px;
}

.size-20 {
  font-size: 20px;
}

.pad-10 {
  padding: 10px;
}

.pad-10-left {
  padding-left: 10px;
}

.pad-10-bottom {
  padding-bottom: 10px;
}

.pad-10-lr {
  padding: 0 10px;
}

.cursor-pointer {
  cursor: pointer;
}

/* .modal-search .search-bar {
  padding-top: 50px;
  background-color: #141414;
} */

.modal-search .search-bar {
  display: grid;
  grid-template-columns: 30px auto;
  padding-top: 60px;
  padding-bottom: 10px;
  background-color: #141414;
}
.ios .modal-search .search-bar {
  padding-top: 100px;
}
.modal-search .search-bar ion-input input {
  font-size: 13px !important;
  padding: 12px 0 !important;
}

.modal-search .search-bar .search-icon {
  color: #666;
}

.modal-search .page-content {
  padding: 10px;
}

ion-icon.close {
  background: rgba(20, 20, 20, 0.8) !important;
  border-radius: 50%;
  font-size: 24px;
}

.info-modal-content {
  /* width: 92vw; */
  max-width: 580px;
}

xxx.info-modal-content .result-box {
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  height: 160px;
  border: 1px solid #333;
  background-image: url("https://takeone.meekd.org/media/dua_lipa_-_cool.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.info-modal-content .result-box {
  /* min-width: 110px;
  max-width: 120px; */
  height: 140px !important;
}

.info-profile {
  display: grid;
  /* grid-gap: 10px; */
  grid-template-columns: 110px auto;
}

.info-modal-actions {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 5fr 1fr 1fr;
  max-width: 500px;
}

.info-modal-actions .info-play ion-button {
  width: 100%;
}

.info-footer {
  border-top: 0.5px solid #191919;
  padding-right: 20px;
  /* margin-top:5px; */
}

.info-footer ion-title {
  padding: 8px !important;
}

.side_shadow,
.side_shadow.right {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    77deg,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0) 85%
  );
  z-index: 1;
}
.side_shadow.right {
  left: inherit;
  right: 0;
  /* width: 100%; */
  background: linear-gradient(
    -77deg,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0) 85%
  );
}

.bottom_shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30%;
  width: 100%;
  z-index: 1;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(15%, rgba(0, 0, 0, 0.15)),
    color-stop(29%, rgba(0, 0, 0, 0.35)),
    color-stop(44%, rgba(0, 0, 0, 0.58)),
    color-stop(68%, #000000),
    to(#000000)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.15) 15%,
    rgba(0, 0, 0, 0.35) 29%,
    rgba(0, 0, 0, 0.58) 44%,
    #000000 68%,
    #000000 100%
  );
}

.all-videos {
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
  margin-top: -15vh;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
  background: transparent !important;
}

/* ion-slides {
  background: gray;
  height: 100px;

}
ion-slide {
  background: blue;
  height: 100px;
  width: 10px;
  max-width: 210px !important;
} */

x.row.rect-small .swiper-slide {
  max-width: 140px !important;
}

ion-slide {
  cursor: pointer;
  max-width: 130px !important;
  margin: 5px;
  /* border: 1px solid #111; */
  border-radius: 4px;
}

x-ion-slide .media-image {
  background-repeat: no-repeat;
  background-size: cover;
}

x-ion-slide .media-track {
  height: 160px;
  width: 130px !important;
  border-radius: 5px;
}

ion-slide .media-title {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(#141414b3),
    to(#111)
  );
  /* background: linear-gradient(transparent, #141414b3, #141414); */
  background: linear-gradient(transparent, #141414b3, #111);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}

ion-slide .media-index {
  width: 40px;
  height: 50px;
  position: absolute;
  top: 0;
  left: -4px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(134deg, #3428834d 0%, #39ffeb57 100%);
  border-bottom-right-radius: 20px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ion-slide.slide-default,
ion-slide.slide-circle,
ion-slide.slide-tall,
ion-slide.slide-wide,
ion-slide.slide-wide-number {
  min-width: 140px;
  min-height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /**** margin-bottom: 40px; */
  margin-bottom: 0px;
}

ion-slide.slide-circle {
  min-height: 200px;
  min-width: 200px;
  border-radius: 50%;
}

ion-slide.slide-tall {
  min-width: 180px;
  min-height: 260px;
}
ion-slide.slide-wide {
  min-width: 220px;
  min-height: 100px;
}

ion-slide.slide-wide-number {
  margin-left: 16px;
  min-width: 200px;
  min-height: 100px;
}
ion-slide.slide-default-number {
  margin-left: 14px;
  margin-left: 50px;
  z-index: 0 !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
}

ion-slide.slide-default-number:first-child,
ion-slide.slide-wide-number:first-child {
  margin-left: 40px;
}
ion-slide.slide-default-number:last-child,
ion-slide.slide-wide-number:last-child {
  margin-left: 70px;
}
ion-slide.slide-default-number .track-number,
ion-slide.slide-wide-number .track-number {
  position: absolute !important;
  /* left: -40px; */
  left: -45px;
  /* top:0; */
  bottom: -38px;
  /* z-index: 10; */
  z-index: -1 !important;
  font-size: 120px !important;
  font-family: "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* font-family: sans-serif, "Helvetica Neue", "Lucida Grande", Arial; */
  font-stretch: expanded;
  letter-spacing: -14px !important;
}
ion-slide.slide-default-number .track-number {
  font-size: 150px;
}
ion-slide.slide-default-number:last-child .track-number {
  left: -85px;
}

ion-slide.slide-tall .media-title {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  text-transform: uppercase;
}

x.main-preview button.track-preview,
x.main-preview .icon_mute_icon {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  position: absolute !important;
  bottom: 10px !important;
  /* border: 1px solid white; */
  padding: 10px;
}

.main-preview button.track-preview {
  left: 10px;
}

.main-preview .icon_mute_icon {
  /* background: #000; */
  border-radius: 50%;
  right: 10px;
}
.icon_mute_icon {
  width: 20px !important;
}

xxx.main-preview video {
  width: 100vw;
  /* height: 70vw; */
  height: 56vw;
  padding: 0;
  margin: 0;
}

.main-preview .vjs-paused video,
.main-preview .vjs-poster {
  width: fit-content;
  display: none !important;
}

.user-sign {
  background: rgba(0, 0, 0, 0.8);
}

.tab-selected {
  color: white;
}

#preview_video_hero {
  max-height: 220px;
}

.main-preview {
  position: relative !important;
}
xxx.main-preview video {
  position: absolute !important;
  /* top:-10px !important; */
  /*  padding: 0 !important;
  margin: 0 !important; */
}

.track-duration {
  position: absolute;
  bottom: 6px;
  right: 1px;
  padding: 2px 5px;
  background: rgba(0, 0, 0, 0.7);
}

ion-icon.modal-close {
  background: transparent !important;
  border-radius: 50%;
  font-size: 24px;
  top: 10px !important;
  right: 10px !important;
}
/* 
@media (min-width: 1280px) {
  ion-app {
    width: 1280px;
    margin: auto;
    position: relative;
  }

  .scroll-content {
    overflow-y: auto;
  }
} */
/****************  */

.ios.top-cover {
  height: 36px;
  width: 100vw;
  position: fixed !important;
  top: 0;
  left: 0;
  background: #000;
  z-index: 999999999;
}
