.hero-main {
  /* height: 80vh;
  min-height: 550px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}
.hero-spacing {
  position: relative;
  display: block;
  margin-top: 290px;
}

.gradient-bottom {
  /* background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, rgba(0, 0, 0, 0)),
        color-stop(15%, rgba(0, 0, 0, 0.15)),
        color-stop(29%, rgba(0, 0, 0, 0.35)),
        color-stop(44%, rgba(0, 0, 0, 0.58)),
        color-stop(68%, rgba(0, 0, 0, 0.58)),
        to(#000000)
      ); */
  /* background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.25) 15%,
    rgba(0, 0, 0, 0.45) 29%,
    rgba(0, 0, 0, 0.58) 44%,
    rgba(0, 0, 0, 0.8) 68%,
    rgba(0, 0, 0, 0.9) 78%,
    #000000 100%
  ); */
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.25) 10%,
    rgba(0, 0, 0, 0.45) 19%,
    rgba(0, 0, 0, 0.58) 30%,
    /* rgba(0, 0, 0, 0.7) 45%, */ rgba(0, 0, 0, 0.9) 58%,
    #000000 100%
  );
}
/* .hero-content {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(15%, rgba(0, 0, 0, 0.15)),
    color-stop(29%, rgba(0, 0, 0, 0.35)),
    color-stop(44%, rgba(0, 0, 0, 0.58)),
    color-stop(68%, #000000),
    to(#000000)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.15) 15%,
    rgba(0, 0, 0, 0.35) 29%,
    rgba(0, 0, 0, 0.58) 44%,
    #000000 68%,
    #000000 100%
  );
} */
.hero-info {
  padding: 20px 0;
  /* background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); */
}
/* .hero-info .title{}
.hero-info .artist{} */
.hero-menu {
  position: relative;
  display: grid;
  /* grid-template-columns: auto 80px auto; */
  grid-template-columns: auto;
  grid-gap: 0px;
  /* background: var(--at-color-secondary); */
  padding: 5px 0;
  /* padding-bottom: 24px; */
}
.hero-menu ion-icon {
  font-size: 22px;
}
.hero-menu ion-label {
  display: block;
  font-size: 10px;
}
.hero-menu ion-button {
  padding: 0 !important;
}
.hero-menu ion-button ion-icon {
  font-size: 16px;
  padding-right: 5px;
}
.hero-menu ion-button ion-label {
  text-transform: none;
  font-size: 12px;
}
/* ion-icon {
    --ionicon-stroke-width: 16px;
} */
.hero-row-1 {
  padding: 10px;
}

.takeone-videos {
  width: 100%;
  /* height: 100%; */
  /* min-height: 100vh; */
  /* margin-top: -15vh; */
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
  background: #000 !important;
}

.track-name {
  position: absolute;
  bottom: -24px;
  left: 0;
  font-size: 14px;
  /* text-align: left; */
  text-align: center;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-artists {
  position: absolute;
  bottom: -38px;
  left: 0;
  font-size: 12px;
  color: #666;
  width: 220px;
  text-align: center;
}

ion-slide.slide-default .track-name,
ion-slide.slide-default .track-artists {
  width: 130px;
}

ion-slide.slide-tall .track-name,
ion-slide.slide-tall .track-artists {
  width: 180px;
  font-size: 16px;
}
ion-slide.slide-tall .track-name {
  font-weight: bold;
  letter-spacing: 0.5px;
}
ion-slide.slide-tall .track-artists {
  font-size: 14px;
}

.hero-content {
  padding: 0 20px;
}

.modal-message.user-sign .modal-wrapper {
  width: 400px;
  min-height: 200px;
  height: auto;
  padding: 40px 0;
}
.modal-message .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal-message .message {
  padding-top: 30px;
}
.error-message {
  color: tomato;
}
.username {
  position: absolute;
  top: 50px;
  right: 12px;
  font-size: 10px;
  text-transform: uppercase;
  color: #aaa;
}
.modal-search .username {
  top: 43px;
}

@media only screen and (max-width: 600px) {
  .hero-content {
    text-align: center;
  }
}

.hollow-text {
  color: #aaa;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1.6px;
  -webkit-text-stroke-color: #aaa;
}

.grid-columns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 20px;
  grid-row-gap: 20px;
}

.result-item {
  border: 1px solid #666;
}
.swiper-slide.doc-file {
  width: 150px !important;
}
.swiper-slide.doc-file img {
  max-height: 80px !important;
}

.grid-list {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  grid-row-gap: 20px;
}
.grid-list-content {
  display: grid;
  grid-template-columns: 60px auto;
  grid-gap: 20px;
  grid-row-gap: 20px;
  align-items: flex-end;
}

.user-icon {
  height: 20px;
  width: 20px;
  padding: 5px;
  background: var(--ion-color-tertiary, #5260ff);
  color: "#ddd";
  border-radius: 50%;
  opacity: 0.8;
  margin-right: 20px;
}

.approve-reject ion-icon {
  /* margin:0 5px; */
  font-size: 30px;
}
.floating-menu {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.85);
  /* padding: 10px 10px 6px 10px; */
  border-radius: 50%;
  cursor: pointer;
}
.floating-menu.web {
  right: 40px;
}
.floating-menu.ios {
  bottom: 70px;
}

.floating-menu:hover {
  background: white;
}
.floating-menu img {
  /* width: 30px !important; */
  /* padding: 2px; */
  padding: 10px 10px 6px 10px;
}
.title-default {
  text-align: left;
  padding: 0;
  margin: 0;
}

/************* Center Vertically AND Horizontally  [start]*/
.at-container {
  position: relative;
}

.at-center-screen {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/************* Center Vertically AND Horizontally  [end]*/

.toolbar-background-md {
  background-color: blue;
}
.toolbar-background-ios {
  background-color: blue;
}

.item-row {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}
.item-row.row-2 {
  grid-template-columns: 1fr 1fr;
}

.user-sign .modal-wrapper {
  background: white;
  color: black;
}
.user-sign .title {
  font-weight: bolder;
  /* font-size: 28px; */
  margin-top: 10px;
  margin-bottom: 10px;
  /* line-height: 40px!important; */
}

.user-sign ion-input,
.user-sign select {
  color: black !important;
  background: transparent !important;
  margin: 0;
  border: 1px solid grey;
  width: 100% !important;
  box-sizing: border-box;
  border-radius: 3px;
}

.sign-field {
  position: relative;
  margin-bottom: 5px;
}

.err-message {
  position: absolute;
  bottom: 4px;
  font-size: 12px;
  color: red;
}
---button[disabled] {
  background: #999 !important;
  cursor: not-allowed !important;
}

/****** Removing input background colour for Chrome autocomplete? [start]
- background color
******/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox - text color*/
input:-webkit-autofill {
  -webkit-text-fill-color: #666 !important;
}
/****** Removing input background colour for Chrome autocomplete? [end] ******/
ion-toolbar + .logo-container {
  position: fixed;
  left: 10px;
  top: 10px;
  /****
  left: 20px;
  top: 20px; */
  z-index: 999;
}
ion-toolbar + .logo-container .main-logo {
  /***** width: 70px; */
  width: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}
ion-toolbar.transparent + .logo-container {
  left: 10px;
  top: 10px;
}
ion-toolbar.transparent + .logo-container .main-logo {
  width: 40px;
}

.user-otp-content {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 22%);
  grid-gap: 15px;
  margin: 30px auto;
}

.user-otp-content ion-input  {
  border: 1px solid #d4d4d4 !important;
 }
.user-otp-content ion-input input {
  padding:10px 0 !important;
}
.user-otp-content ion-input.has-focus {
  box-sizing: border-box;
  outline: #5260ff 1px solid !important;
}

.at-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.at-flex-center-start{
  align-items: flex-start;
  justify-content: center;
}
.at-flex-center-end{
  align-items: flex-end;
  justify-content: center;
}