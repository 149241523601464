:root {
  --at-color-primary: #e5111d;
  --at-color-secondary: #141414;
}

/* ion-tab-bar {
  color: #ffffff;
  background-color: #1a1a1a !important;
}

ion-tabs {
  color: #ffffff;
  background: #141414 !important;
} */

.slide-in-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.slide-in-right.ng-enter, .slide-in-right>.ng-enter {
  -webkit-transition: all cubic-bezier(0.1, 0.7, 0.1, 1) 400ms;
  transition: all cubic-bezier(0.1, 0.7, 0.1, 1) 400ms;
}

.slide-in-right.ng-enter-active, .slide-in-right>.ng-enter-active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.slide-in-right.ng-leave, .slide-in-right>.ng-leave {
  -webkit-transition: all ease-in-out 250ms;
  transition: all ease-in-out 250ms;
}

html, ion-content {
  scroll-behavior: smooth;
}

a, button {
  cursor: pointer;
}

a:hover, x-button:hover {
  opacity: .5;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  opacity: .5;
}

.at-left {
  text-align: left;
}

.at-right {
  text-align: right;
}

.at-center {
  text-align: center;
}

.at-justify {
  text-align: justify;
}

.at-bold {
  font-weight: bold;
}

.size-10 {
  font-size: 10px !important;
}

.size-12 {
  font-size: 12px !important;
}

.size-14 {
  font-size: 14px !important;
}

.size-16 {
  font-size: 16px !important;
}

.size-18 {
  font-size: 18px !important;
}

.size-20 {
  font-size: 20px !important;
}

.size-24 {
  font-size: 24px !important;
}
.size-26 {
  font-size: 26px !important;
}
.size-28 {font-size: 28px !important;}
.size-30 {font-size: 30px !important;}
.size-36 {font-size: 36px !important;}
.size-40 {font-size: 40px !important;}
.size-46 {font-size: 46px !important;}

.pad-5 {
  padding: 5px;
}

.pad-10 {
  padding: 10px;
}
.pad-20 {
  padding: 20px;
}

.pad-5-top {
  padding-top: 5px ;
}
.pad-5-bottom {
  padding-bottom: 5px ;
}
.pad-5-left {
  padding-left: 5px ;
}
.pad-5-right {
  padding-right: 5px ;
}

.pad-5-lr {
  padding:0 5px ;
}
.pad-5-tb {
  padding: 5px 0;
}

.pad-10-tb {
  padding: 10px 0;
}

.pad-10-top {
  padding-top: 10px;
}

.pad-20-top {
  padding-top: 20px;
}

.pad-10-bottom {
  padding-bottom: 10px;
}

.pad-20-bottom {
  padding-bottom: 20px;
}

.pad-10-right {
  padding-right: 10px;
}

.pad-20-left {
  padding-left: 20px;
}
.pad-20-right {
  padding-right: 20px;
}

.color-000 {
  color: #000;
}

.color-444 {
  color: #444;
}

.color-666 {
  color: #666;
}

.color-888 {
  color: #888;
}

.color-aaa {
  color: #aaa;
}

.color-ccc {
  color: #ccc;
}

.at-button {
  text-transform: none;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: var(--color);
  background: var(--ion-color-secondary);
  width: 100%;
  height: 100%;
  max-height: 36px;
  border-radius: 5px;
  padding: 10px;
}

.at-button:hover {
  opacity: 0.5;
}

.grid {
  display: grid;
}

.grid-col-2 {
  grid-template-columns: repeat(2, auto);
}

.grid-col-3 {
  grid-template-columns: repeat(3, auto);
}

.grid-col-4 {
  grid-template-columns: repeat(4, auto);
}

/* ellipsis */

.line-clamp, .line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

.page-search-content {
  padding: 10px !important;
}

.page-search-content .search-box {
  display: grid;
  grid-template-columns: auto 60px;
  grid-gap: 10px;
}

.page-search-content ion-input {
  background: rgb(40, 39, 41) !important;
  border-radius: 10px;
}

.page-search-content ion-input input {
  padding-left: 40px !important;
}

.page-search-content ion-input img {
  position: absolute;
  left: 10px;
  opacity: 0.5;
}

.page-search-content .search-box button.cancel {
  cursor: pointer;
  color: #fff;
  background: none;
}

.page-search-content .search-category-title {
  padding: 10px 0;
}

.page-search-content .search-result {
  /* margin-top: 20px; */
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  /***** grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  grid-gap: 10px;
}
.page-search-content.mylist .search-result {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}


.page-search-content .result-box, .info-modal-content .result-box {
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  height: 160px;
  /* height: 140px; */
  border: 1px solid #333;
  /* border: 1px solid #aaa; */
  /* background-image: url('https://takeone.meekd.org/media/dua_lipa_-_cool.jpg'); */
  background-image:none;
  background-position: center;
  background-size: cover;
  border-radius: 5px;

}
.page-search-content .result-box{
  /***** margin-bottom: 40px; */
  margin-bottom: 0px;
}

.page-search-content .result-box-title, .page-search-content .result-box-category {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -42px;
  width: 100%;
  font-size: 12px;
  /* padding:5px; */
  height: 40px;
  /* background: rgba(0, 0, 0, 0.9); */
}

.page-search-content .result-box-title {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page-search-content .result-box-category {
  top: 0;
  height: 20px;
  font-size: 10px;
  bottom: initial;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-page .back {
  cursor: pointer;
  color: #fff;
  background: none;
  font-size: 20px;
  ;
}

.modal-page ion-toolbar {
  padding: 0 10px !important;
  height: 50px !important;
}

.modal-page .modal-page-content {
  padding: 10px !important;
}

x.main-preview {
  position: relative;
  cursor: pointer;
  height: 56vw;
  /* border: 1px solid #aaa; */
  background-position: center;
  background-size: cover;
}

.modal-page .modal-page-content ion-button {
  text-transform: none;
  width: 100%;
}

.at-tabs {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10;
}

.at-tab {
  border-top: 3px solid transparent;
  cursor: pointer;
  padding: 10px 0;
  width: 75%;
}

.at-tab.active {
  box-sizing: border-box;
  border-top: 3px solid red;
}

/* .album-tracks {
  padding: 20px 0;
} */

.album-track {
  grid-template-columns: 80px auto 20px;
  grid-gap: 10px;
  cursor: pointer;
  ;
}

.album-track .play-track:hover, .album-track ion-icon:hover {
  opacity: .5;
}

.play-track {
  position: relative;
  ;
}

.play-track ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
}

.main-preview button.track-preview, .main-preview .icon_mute_icon {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  position: absolute !important;
  bottom: 10px;
  /* border: 1px solid white; */
  padding: 10px;
}

.main-preview button.track-preview {
  left: 10px;
}

.main-preview .icon_mute_icon {
  /* background: #000; */
  border-radius: 50%;
  right: 10px;
}

.main-preview video {
  width: 100vw;
  /* height: 56vw; */
  max-height: 500px !important;
  padding: 0;
  margin: 0;
}

.main-preview .vjs-paused video, .main-preview .vjs-poster {
  width: fit-content;
  display: none !important;
}

.grid-menu {
  /* grid-template-columns: 30px auto 30px; */
  /* grid-template-columns: 90px auto 90px; */
  grid-template-columns: auto auto;
}

.grid-menu-3 {
  grid-template-columns: 10px auto 90px;
}

.avatar {
  /* background: white; */
  background: var(--at-color-primary);
}

ion-button.sign-in {
  background: var(--at-color-primary);
}

.toolbar-title-content {
  display: grid;
  grid-template-columns: 30px auto 30px;
}

.user-sign .modal-wrapper {
  width: 480px;
  min-height: 600px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  /* margin:10px; */
}

.user-sign .modal-wrapper .ion-page {
  padding: 40px;
  overflow: auto;
}
.user-sign .modal-wrapper {
  background-color: #111;
}

.user-sign .modal-wrapper ion-button {
  width: 100%;
  text-transform: initial;
}

.user-sign .modal-wrapper select, 
.user-sign .modal-wrapper ion-input, 
.user-sign .modal-wrapper button {
  box-sizing: border-box;
  width: 100%;
  padding: 15px !important;
  /* background: var(--at-color-primary); */
  background: black;
  color: white;
  letter-spacing: 1px;
  /* border-radius: 5px; */
  margin-bottom: 20px;
}


.user-sign .modal-wrapper select, 
.user-sign .modal-wrapper ion-input {
  padding: 5px !important;
  /* background: rgba(100, 100, 100, .75); */
  background: transparent;
}
.user-sign .modal-wrapper select {
  padding: 15px !important;
}
/* .user-sign .modal-wrapper a { 
  color:var(--at-color-primary);
} */

/***** style settings for scrollbar [start] *****/

/* width */

::-webkit-scrollbar {
  width: 6px !important;
  height: 10px !important;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff !important;
  border-radius: 10px !important;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: grey !important;
  border-radius: 10px !important;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #b30000 !important;
}

/***** style settings for scrollbar [end] *****/

/* 
 ion-modal{
  position: absolute; 
   box-sizing: border-box;
  height: 82%;
 } */

ion-toast {
  --max-width:180px;
  text-align:center;
}