.modal-message.modal-viewer .modal-wrapper {
  width: 100vw !important;
  height: 100vh !important;
  /* height: auto; */
  padding: 40px 0;
}

.modal-message.modal-viewer .modal-wrapper .modal-content {
  overflow: scroll;
}

.pg-viewer-wrapper .photo-viewer-container img {
  max-height: 80vh;
  /* max-width: 800px; */
}
.pg-viewer-wrapper .document-container {
  color: initial !important;
  background: initial !important;
  width: auto !important;
}
.pg-viewer-wrapper .pdf-viewer-container {
  text-align: center;
  width: 100vw;
}
.pg-viewer-wrapper .pdf-viewer {
  padding-top: 40px;
  width: 100% !important;
  /* height: 100vh !important; */
  z-index: 9999;
}
.pg-viewer-wrapper video {
  width: 100%;
  max-width: 800px;
}

.modal-message .close {
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 999;
}

.photo-viewer-container {
  /* padding:100px; */
  padding: 0 !important;
  /* margin: auto; */
  height: 100vh !important;
  width: 100vw !important;
}
.pg-viewer-wrapper img {
  width: initial !important;
  height: initial !important;
}

x.pdf-canvas canvas {
  width: 100% !important;
}

.react-pdf__Page__canvas {
  height: auto !important;
  width: 100vw !important;
  max-width: 1000px;
  margin: auto;
}

ion-slides.image-slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ion-segment-button {
  --indicator-box-shadow: transparent !important;
  --background:white;
  --color:#2d2e87;
  --background-checked: linear-gradient(to right, #2d2e87, #0083CB)!important;
  --color-checked: white;
  border-radius: .5rem !important;
  padding: 2px;
  margin: 0;
} */
