.file-uploader-window{
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    z-index: 999;
}
.parent-box {
  width: 100%;
  height: 100vh;
  background-image: url("https://artist.takeone.studio/img/concert.jpg");
  /* background-image: url("https://artist.takeone.studio/img/band.jpg"); */
  /* background-image: url("https://uploader.app.takeone.studio/images/band.jpg"); */
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.parent-box .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
  background-size: 300%;
  -webkit-animation: animated 10s infinite ease-in-out;
  animation: animated 10s infinite ease-in-out;
}

.parent-box .upload-box {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.parent-box .upload-box .upload-setting {
  width: 100%;
  height: 65%;
  border-radius: 25px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  overflow-y: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
}

.parent-box .upload-box .upload-setting .button-box {
  width: 100%;
  height: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.parent-box .upload-box .upload-setting .button-box .upload-button {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: white;
  -webkit-box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.parent-box .upload-box .upload-setting .button-box .upload-button span {
  font-size: 10px;
  color: black;
  font-weight: bolder;
}

.parent-box .upload-box .upload-setting .button-box .upload-button img {
  width: 70%;
}

.parent-box .upload-box .upload-setting .files-box {
  width: 100%;
  height: 70%;
  /* margin-top: 20px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-radius: 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.parent-box .upload-box .upload-setting .files-box .files {
  margin: 10px 0;
  position: relative;
}

.parent-box .upload-box .upload-setting .files-box .files span {
  color: black;
  font-size: 15px;
}

.parent-box .upload-box .upload-setting .files-box .files .progress-comp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.288);
  border-radius: 50px;
}

x.MuiLinearProgress-barColorPrimary {
  background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}

.parent-box .upload-box .upload-setting .files-box .files .progress-bar {
  position: relative;
  z-index: 1;
  margin-top: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #89d8d3;
  background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 9px;
}

.parent-box .upload-box .qoutes {
  width: 100%;
  height: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.parent-box .upload-box .qoutes img {
  width: 100%;
}

.media-uploader {
  box-sizing: border-box;
  /* background: blue !important; */
  background-image: url("https://artist.takeone.studio/images/upload.png") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  background-color: #fff;
  cursor: pointer;
  max-height: 360px !important;
  width: 100% !important;
  border: 2px dashed #a0a0a0;
  border-radius: 10px;
}
.arabic .media-uploader {
  background-image: url("https://artist.takeone.studio/images/upload-ar.png") !important;
}

.media-uploader.video {
  background-image: url("https://artist.takeone.studio/images/video-upload.png") !important;
}
.arabic .media-uploader.video {
  background-image: url("https://artist.takeone.studio/images/video-upload-ar.png") !important;
}

.media-uploader:hover {
  opacity: 0.5;
}

@media only screen and (min-width: 701px) {
  .media-uploader {
    max-height: 360px !important;
    width: 100% !important;
  }
  .parent-box .upload-box {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    justify-content: center;
    flex-direction: row !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    z-index: 1;
  }
  .parent-box .upload-box .upload-setting {
    width: 80% !important;
    max-width: 400px;
    height: 100% !important;
    border-radius: 25px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
      rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
      rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
  }
  .parent-box .upload-box .upload-setting .upload-button {
    width: 200px !important;
    height: 200px !important;
    border-radius: 100%;
    background: white;
    -webkit-box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .parent-box .upload-box .upload-setting .upload-button span {
    font-size: 10px;
    color: black;
    font-weight: bolder;
  }
  .parent-box .upload-box .upload-setting .upload-button img {
    width: 70%;
  }
  .parent-box .upload-box .qoutes {
    width: 60% !important;
    height: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .parent-box .upload-box .qoutes img {
    width: 90% !important;
  }
}


.MuiGrid-root .MuiGrid-item {
    margin-bottom: 10px !important;
    color: black;
    font-size: 14px;
  }
  
  .MuiLinearProgress-root {
    height: 12px !important;
    border-radius: 5px;
  }
    
  .MuiLinearProgress-root[aria-valuenow="100"].error .MuiLinearProgress-bar {
    background: red !important;
  }
  .MuiTypography-colorError{
    font-weight: bold !important;
  }
  
  .MuiLinearProgress-root[aria-valuenow="100"] .MuiLinearProgress-bar {
    background: green !important;
  }
   
  .submit_button button{
    margin-top: 20px;
    padding:10px !important;
  }
  
  